export const AUTH_TOKEN_KEY = 'droppii-token'

export const AUTH_REFRESH_TOKEN_KEY = 'droppii-refresh-token'

export const IMAGE_PREFIX = `${process.env.NEXT_PUBLIC_BASE_URL}/uploader-service/v1/uploader`

export const USER_ORDER_KEY_PREFIX = 'order-'

export const BANNER_AUPLAY_DURATION = 3000

export const MAX_REVIEW_COMMENT_CHARACTER = 255

export const MAX_REVIEW_IMAGES = 6

export const MINIMUM_TOP_UP = 2000000
