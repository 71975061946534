import { INITIAL_VIEWPORTS } from '@storybook/addon-viewport'
import packageJSON from '../../package.json'

export const storybookMobileConfig = {
  viewport: {
    //👇 The viewports you want to use
    viewports: INITIAL_VIEWPORTS,
    //👇 Your own default viewport
    defaultViewport: 'iphonexr',
  },
}

export const IS_DEV = process.env.NODE_ENV === 'development'

export const VALID_LOCALES = ['vi-VN', 'en-US', 'km-KH']
export type Locale = 'vi-VN' | 'en-US' | 'km-KH'

export function getLocale() {
  if (process.env.NEXT_PUBLIC_LOCALE == null) return 'vi-VN'
  return VALID_LOCALES.includes(process.env.NEXT_PUBLIC_LOCALE)
    ? process.env.NEXT_PUBLIC_LOCALE
    : 'vi-VN'
}

declare global {
  interface Window {
    DROPPII: any
  }
}

export function injectAppContext() {
  const locale = getLocale().split('-')[1].toLocaleLowerCase()

  if (typeof window === 'object') {
    window.DROPPII = {
      version: `${locale}@${packageJSON.version}`,
    }
  }
}
